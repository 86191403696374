<template>
  <div id="buy">
    <router-view v-bind.sync="form" :isDigitalItem="isDigitalItem" @pay="pay"></router-view>
  </div>
</template>

<script>
export default {
  data () {
    return {
      form: {
        user_id: null,
        artist_id: null,
        code: null,
        price: null,
        last_name: null,
        first_name: null,
        last_kana: null,
        first_kana: null,
        zip: null,
        prefecture: null,
        city: null,
        street: null,
        building: null,
        tel: null,
        message: null,
        email: null,
        card_token: null, //  Pay.JP クレジットカードID
      }
    }
  },
  computed: {
    cart () {
      return this.$store.state.cart;
    },
    loginUser () {
      return this.$store.state.loginUser;
    },
    isDigitalItem () {
      return this.$store.state.cart[0].type && this.$store.state.cart[0].type === 'digital';
    },
  },
  methods: {
    async pay (card_token) {
      const options = {
        order: this.form,
        items: this.cart,
        card_token: card_token,
      }
      try {
        await this.$http.post('orders', options);
        this.$router.push({name: 'cart-send'});
      } catch (e) {
        this.$toasted.error('決済に失敗しました', {
          position: 'top-center',
          duration: 2000,
        });
      }
    },
  },
  created () {
    this.form.user_id = this.loginUser.id;
    this.form.last_name = this.loginUser.last_name;
    this.form.first_name = this.loginUser.first_name;
    this.form.last_kana = this.loginUser.last_kana;
    this.form.first_kana = this.loginUser.first_kana;
    this.form.email = this.loginUser.email;
    this.form.zip = this.loginUser.zip;
    this.form.prefecture = this.loginUser.prefecture;
    this.form.city = this.loginUser.city;
    this.form.street = this.loginUser.street;
    this.form.building = this.loginUser.building;
    this.form.tel = this.loginUser.tel;
    if (this.cart && this.cart.length) {
      this.form.artist_id = this.cart[0].artist_id;
    }
  }
}
</script>
